import React from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import { TourDates } from "../layouts/TourDates/tour-dates.component"
import { graphql, useStaticQuery } from "gatsby"
import HomeHero from "../layouts/HomeHero"
import Gallery from "../layouts/Gallery"
import WhatToExpect from "../layouts/WhatToExpect"
import InstagramSection from "../layouts/InstagramSection"
import InfoBanner from "../layouts/InfoBanner"
import { WhoAmI } from "../layouts/WhoAmI/who-am-i.component"
import { FeaturedOn } from "../layouts/FeaturedOn/featured-on.component"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "main-hero.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      image1: file(relativePath: { eq: "1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      image2: file(relativePath: { eq: "2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      image3: file(relativePath: { eq: "3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      image4: file(relativePath: { eq: "4.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      profilePhoto: file(relativePath: { eq: "profile.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      tourDates: file(relativePath: { eq: "tourdates-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      instagramPostPhoto: file(relativePath: { eq: "whoami.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      whoImg: file(relativePath: { eq: "whoImg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      whoSignature: file(relativePath: { eq: "signature.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      abcNews: file(relativePath: { eq: "abcNews.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      ap: file(relativePath: { eq: "ap.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      businessIns: file(relativePath: { eq: "businessIns.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      foxNews: file(relativePath: { eq: "foxNews.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      nbcNews: file(relativePath: { eq: "nbcNews.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      sharks: file(relativePath: { eq: "sharks.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      {/*<LayoutTitle>HomeHero</LayoutTitle>*/}
      <HomeHero
        bgImage={staticQuery.home}
        title={`The <span>Austin Rutherford</span> Book Tour`}
        tag="10 City Tour for Real Estate Investors"
        description="Start or grow your real estate investing business with proven techniques from someone who has done hundreds of deals."
      />
      <Gallery
        images={[
          staticQuery.image1,
          staticQuery.image2,
          staticQuery.image3,
          staticQuery.image4,
        ]}
      />
      <LayoutTitle>WhatToExpect</LayoutTitle>
      <WhatToExpect
        title="What to expect"
        description="Each stop on this 10 city tour will be packed with value for investors looking to scale their business, or new investors looking to get off the ground."
        items={[
          {
            title: "30min networking session",
            content: `<ul><li>Meet Austin and other real estate movers and shakers in your market.</li></ul>`,
          },
          {
            title: "30-min Austin teaches how to “Get Free Houses” ",
            content: `<ul><li>Finding great deals</li>
                      <li>Funding deals (with other people’s money)</li>
                      <li>Building generational wealth through real estate</li>
                      </ul>`,
          },
          {
            title:
              "60-90 min Q&A with Austin and a special guest from your city!",
            content: `<ul><li>Get ALL of your questions answered. This is your chance to be selfish and ask your question. I want to help YOU go to the next level. </li>
              
                  </ul>`,
          },
        ]}
        subtitle={`Learn how to <b>“Get Free Houses”</b> with Austin Rutherford`}
      />
      <LayoutTitle>InstagramSection</LayoutTitle>
      <InstagramSection
        profilePhoto={staticQuery.profilePhoto}
        username="Feed Template"
        qtyPost="11"
        instagramPosts={[
          {
            postImg: staticQuery.instagramPostPhoto,
            username: "escommerce",
            publicationDate: "on May 17, 2022",
            description:
              "With a focus on empowering real estate investors, Empora is proud to\n" +
              "            support the Austin Rutherford Book Tour. A digital-first title\n" +
              "            company, Empora has redesigned the title and closing process to save\n" +
              "            real estate investors $750 in closing costs and streamline the\n" +
              "            entire process - enabling to you scale your business.",
          },
          {
            postImg: staticQuery.instagramPostPhoto,
            username: "escommerce",
            publicationDate: "on May 17, 2022",
            description: "New event!",
          },
          {
            postImg: staticQuery.instagramPostPhoto,
            username: "escommerce",
            publicationDate: "on May 17, 2022",
            description: "New event!",
          },
        ]}
      />
      <LayoutTitle>TourDates</LayoutTitle>
      <TourDates
        bgImage={staticQuery.tourDates}
        title={"Tour Dates"}
        text={"It’s free to attend. <br>Seating is limited. RSVP asap! "}
        tours={[
          {
            city: "Cleveland, OH",
            date: "Wenesday, July 6th",
          },
          {
            city: "Pittsburgh, PA",
            date: "Thursday July 7th",
          },
          {
            city: "Cleveland, OH",
            date: "Wenesday, July 6th",
          },
          {
            city: "Pittsburgh, PA",
            date: "Thursday July 7th",
          },
          {
            city: "Cleveland, OH",
            date: "Wenesday, July 6th",
          },
          {
            city: "Pittsburgh, PA",
            date: "Thursday July 7th",
          },
          {
            city: "Cleveland, OH",
            date: "Wenesday, July 6th",
          },
          {
            city: "Pittsburgh, PA",
            date: "Thursday July 7th",
          },
          {
            city: "Cleveland, OH",
            date: "Wenesday, July 6th",
          },
          {
            city: "Pittsburgh, PA",
            date: "Thursday July 7th",
          },
        ]}
        btnText={"RSVP"}
      />
      <LayoutTitle>WHO AM I</LayoutTitle>
      <WhoAmI
        img={staticQuery.whoImg}
        title={"<b>Who</b> Am I?"}
        text={
          "At the age of 20 years, old Austin bought his first rental property in Dec 2013. It took him 20 years to save enough money to buy that property, he quickly realized he had to make money faster to reach his investment goals. He invested into a coaching program at 21 and flipped his first house at 22 after raising $247,000 from private money lenders to fund that deal, he made $107,000 in profit on that first deal. \n" +
          "<br><br>" +
          "He quickly reinvested all of that money back into the business and has flipped and wholesaled hundreds of houses and owns almost 100 units.\n" +
          "<br><br>" +
          "Today, he is still growing and investing in real estate. His company Elevate Life teaches other aspiring entrepreneurs how to invest in all aspects of real estate. Austin’s goal is to continue to add as much value back into the community as possible and to inspire others to become the best version of themselves! \n"
        }
        signature={staticQuery.whoSignature}
      />
      <LayoutTitle>InfoBanner</LayoutTitle>
      <InfoBanner
        title=" Proudly Presented by <span>Empora Title</span>"
        description="With a focus on empowering real estate investors, Empora is proud to
            support the Austin Rutherford Book Tour. A digital-first title
            company, Empora has redesigned the title and closing process to save
            real estate investors $750 in closing costs and streamline the
            entire process - enabling to you scale your business."
      />

      <LayoutTitle>Featured On</LayoutTitle>
      <FeaturedOn
        imgs={[
          {
            image: staticQuery.abcNews,
          },
          {
            image: staticQuery.ap,
          },

          {
            image: staticQuery.sharks,
          },
          {
            image: staticQuery.foxNews,
          },
          {
            image: staticQuery.nbcNews,
          },
          {
            image: staticQuery.businessIns,
          },
        ]}
        title={"FEatured On"}
      />
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />
    </Layout>
  )
}

export default Layouts
